import { createApp, markRaw } from 'vue'
import { setupCalendar, Calendar, DatePicker } from 'v-calendar'
import { createPinia } from 'pinia'
import Toast, { POSITION } from 'vue-toastification'
import { Tooltip } from 'bootstrap'
import { createHead } from '@unhead/vue'
import Popper from 'vue3-popper'

import * as Sentry from '@sentry/vue'

import './assets/scss/app.scss'

import PageHeader from '@/components/PageHeader.vue'
import UserCard from '@/components/userCard/UserCard.vue'

import Autocomplete from '@trevoreyre/autocomplete-vue'

import ShoutoutCard from '@/components/ShoutoutCard.vue'
import CelebrationCard from '@/components/CelebrationCard.vue'
import AllowanceLedgerCard from '@/components/AllowanceLedgerCard.vue'
import RewardLedgerCard from '@/components/RewardLedgerCard.vue'
import ChallengeCard from '@/components/ChallengeCard.vue'
import GiftCard from '@/components/GiftCard.vue'
import ChallengeClaimCard from '@/components/ChallengeClaimCard.vue'
import NominationCard from '@/components/NominationCard.vue'
import CelebrationAnnouncement from '@/components/CelebrationAnnouncement.vue'

import LoadingSpinner from '@/components/LoadingSpinner.vue'
import FlowerLoader from '@/components/FlowerLoader.vue'

import FormBuilder from '@/components/admin/formBuilder/FormBuilder.vue'
import FormViewer from '@/components/client/formViewer/FormViewer.vue'
import NumberSpinner from '@/components/NumberSpinner.vue'
import FileUploader from '@/components/FileUploader.vue'
import SegmentSelector from '@/components/admin/SegmentSelector.vue'
import BulkUserSelector from '@/components/client/BulkUserSelector.vue'

import ReactionBar from '@/components/client/ReactionBar.vue'
import CommentBar from '@/components/client/CommentBar.vue'

import ShippingAddressSelector from '@/components/client/ShippingAddressSelector.vue'

import App from './App.vue'
import router from './router'
import stripe from './stripe'

const pinia = createPinia()

pinia.use(({ store }) => {
  store.$router = markRaw(router)
})

const app = createApp(App)

const head = createHead()
app.use(head)

app.component('PageHeader', PageHeader)
app.component('UserCard', UserCard)
app.component('LoadingSpinner', LoadingSpinner)
app.component('ShoutoutCard', ShoutoutCard)
app.component('CelebrationAnnouncement', CelebrationAnnouncement)
app.component('GiftCard', GiftCard)
app.component('CelebrationCard', CelebrationCard)
app.component('RewardLedgerCard', RewardLedgerCard)
app.component('AllowanceLedgerCard', AllowanceLedgerCard)
app.component('ChallengeClaimCard', ChallengeClaimCard)
app.component('NominationCard', NominationCard)
app.component('FormBuilder', FormBuilder)
app.component('FormViewer', FormViewer)
app.component('NumberSpinner', NumberSpinner)
app.component('SegmentSelector', SegmentSelector)
app.component('ChallengeCard', ChallengeCard)
app.component('FileUploader', FileUploader)
app.component('BulkUserSelector', BulkUserSelector)
app.component('FlowerLoader', FlowerLoader)
app.component('ReactionBar', ReactionBar)
app.component('CommentBar', CommentBar)
app.component('ShippingAddressSelector', ShippingAddressSelector)
app.component('VPopper', Popper)
app.component('AutoComplete', Autocomplete)

app.directive('tooltip', {
  mounted(el) {
    // eslint-disable-next-line no-unused-vars
    const tt = new Tooltip(el, {
      title: (e) => e.getAttribute('data-bs-title'),
      trigger: 'hover',
    })
  },
})

app.directive('autofocus', {
  mounted(el) {
    setTimeout(() => el.focus(), 10)
  },
})

const clickToCopyDirective = {
  handleMouseDown(e) {
    e.preventDefault()
    const { target } = e
    target.select()

    document.execCommand('copy')
  },
  mounted(el) {
    el.addEventListener('mousedown', clickToCopyDirective.handleMouseDown)
  },
  beforeUnmount(el) {
    el.removeEventListener('mousedown', clickToCopyDirective.handleMouseDown)
  },
}

app.directive('clickToCopy', clickToCopyDirective)

Sentry.init({
  enabled: import.meta.env.PROD,
  dsn: 'https://2a4a36184e796197300690fc528471ea@o389293.ingest.sentry.io/4506581139193856',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/api.hithrive\.com\//],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app
  .use(router)
  .use(pinia)
  .use(setupCalendar, {})
  .use(stripe)
  .use(Toast, {
    position: POSITION.TOP_CENTER,
    hideProgressBar: true,
  })
  .component('VCalendar', Calendar)
  .component('VDatePicker', DatePicker)
  .mount('#app')
