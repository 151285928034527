<script setup>
import { readonly, reactive, ref, computed } from 'vue'
import { toHTML } from 'slack-markdown'
import { useUserStore } from '@/store'
import { storeToRefs } from 'pinia'
import { useLocaleFormatter } from '@/utils'
import { DateTime } from 'luxon'
import { Challenges } from '@/api'
import { useToast } from 'vue-toastification'

const toast = useToast()

const props = defineProps({ data: Object })
const emit = defineEmits(['modalClose', 'modalDone'])

const { formatPoints } = useLocaleFormatter()

const userStore = useUserStore()
const { team } = storeToRefs(userStore)

const loading = ref(false)
const challenge = readonly(props.data.challenge)
const previewMode = readonly(props.data.previewMode)

const endDate = () => {
  if (typeof challenge.end_date === 'string') {
    return challenge.end_date
  }
  if (challenge.end_date) {
    return challenge.end_date.toISOString()
  }
  return challenge.end_date
}

const endTime = DateTime.fromISO(endDate(), { zone: 'utc' }).setZone(
  team.value.timezone,
  { keepLocalTime: true }
)

const cfForm = reactive({})

const canClaim = ref(false)

const rejectionNote = computed(() => {
  if (challenge.claim.status === 'REJECTED') {
    const moderationHistoryLength = challenge.claim.moderation_history.length
    const mostRecentModeration =
      challenge.claim.moderation_history[moderationHistoryLength - 1]
    return mostRecentModeration.note
  }
  return null
})

const moderatorName = computed(() => {
  const moderationHistoryLength = challenge.claim.moderation_history.length
  const mostRecentModeration =
    challenge.claim.moderation_history[moderationHistoryLength - 1]
  return mostRecentModeration.user.full_name
})

const statusBlurbClasses = computed(() => ({
  'alert alert-danger': challenge.claim.status === 'REJECTED',
  'alert alert-warning': challenge.claim.status === 'PENDING',
}))

const customFields = computed(() => {
  console.log(challenge)
  if (challenge.claim) {
    return challenge.claim.custom_fields
  }
  return challenge.custom_fields
})

function close() {
  emit('modalClose', true)
}

async function submitClaim() {
  const input = { id: challenge.id, customFields: cfForm }

  if (
    !props.data?.claim ||
    Object.keys(props.data?.claim.submitted_fields).length === 0
  ) {
    input.customFieldLabels = props.data?.challenge.custom_fields
  }

  console.log(input)
  loading.value = true
  const [err, resp] = await Challenges.claimChallenge(input)
  loading.value = false

  if (err) {
    toast.error('We had trouble claiming this challenge. Please try again.')
    return
  }

  toast.success(`Your claim is being reviewed.`)

  emit('modalDone', resp)
}
</script>

<template>
  <aside class="ht-modal">
    <div class="ht-dialog">
      <header>
        <button class="close" @click="close">
          <span class="sr-only">Close modal window</span>
        </button>
      </header>
      <main>
        <div class="banner">
          <div
            v-if="previewMode"
            :class="['preview-mode-pill', 'claim-status']"
          >
            Preview mode
          </div>
          <img
            :src="`${challenge.banner_image.src}&w=730&h=313&fit=crop&crop=focalpoint`"
          />
          <cite>
            Photo by
            <a :href="challenge.banner_image.credit.link" target="_blank">{{
              challenge.banner_image.credit.name
            }}</a>
            on Unsplash
          </cite>
        </div>
        <div class="content">
          <h1>{{ challenge.title }}</h1>

          <div class="details">
            <span v-if="challenge.points > 0" class="points"
              >+{{ formatPoints(challenge.points) }}</span
            >

            <span v-if="challenge.end_date" class="end-time">{{
              endTime.diffNow().as('days') > 3
                ? `Ends on ${endTime.toLocaleString(DateTime.DATE_MED)}`
                : `Ends ${endTime.toRelative()}`
            }}</span>
          </div>

          <div class="description" v-html="toHTML(challenge.description)"></div>

          <template v-if="challenge.claim">
            <div
              v-if="challenge.claim.status === 'REJECTED'"
              class="status-blurb"
            >
              <div :class="statusBlurbClasses">
                <div class="status-header">
                  {{
                    `Your submission has been rejected by ${moderatorName} for the following reason:`
                  }}
                </div>
                <div v-if="rejectionNote" class="rejection-note">
                  {{ rejectionNote }}
                </div>
              </div>
            </div>
            <div
              v-else-if="challenge.claim.status === 'PENDING'"
              class="empty-content-placeholder"
            >
              <h5>Your submission is being reviewed</h5>
              <small
                >You'll receive a notification when your challenge submission
                has been approved or rejected.</small
              >
            </div>
          </template>

          <template
            v-if="
              (!challenge.claim || challenge.claim.status === 'REJECTED') &&
              customFields.length > 0
            "
          >
            <form-viewer
              v-model="cfForm"
              :fields="customFields"
              @validate="canClaim = $event"
            />
          </template>
        </div>
      </main>

      <footer>
        <div class="actions">
          <button class="btn btn-gray" @click="close">Cancel</button>
          <button
            class="btn btn-primary"
            :disabled="!canClaim || loading || previewMode"
            @click="submitClaim"
          >
            <template v-if="!loading">{{
              challenge.claim && challenge.claim.status === 'REJECTED'
                ? 'Resubmit Challenge Claim'
                : 'Claim Challenge'
            }}</template>
            <loading-spinner v-else />
          </button>
        </div>
      </footer>
    </div>
  </aside>
</template>

<style lang="scss" scoped>
.ht-dialog {
  padding-top: 0 !important;

  > header {
    background-color: transparent !important;
    border-bottom: 0 !important;
    box-shadow: none !important;

    .close {
      background: $light !important;
    }
  }

  > main {
    > .banner {
      margin: -25px -25px 0;
      position: relative;

      > .preview-mode-pill {
        border-radius: 100em;
        position: absolute;
        font-weight: 600;
        letter-spacing: 1px;
        font-size: 10px;
        text-transform: uppercase;
        padding: 0 7px;
        line-height: 16px;
        top: 10px;
        left: 10px;
        background: $secondary;
        color: #fff;
      }

      > img {
        width: 100%;
        height: auto;
      }

      > cite {
        display: block;
        position: absolute;
        bottom: 25px;
        right: 25px;
        color: #fff;
        font-size: 8px;
        line-height: 10px;
        padding: 0;
        text-shadow: rgba(0, 0, 0, 0.4) 0 1px 2px;

        > a {
          color: #fff;
        }
      }
    }

    > .content {
      background: #fff;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      position: relative;
      padding: 25px 25px 0;
      margin: -12px -25px 0;

      > h1 {
        font-weight: 600;
        font-size: 24px;
        margin: 0 0 0.5rem;
      }

      > h4 {
        font-family: $font-family-sans-serif;
        margin: 15px 0 0;
        font-size: 14px;
      }

      > .description {
        margin: 0 0 1rem;
      }

      > .details {
        margin: 0 0 1rem;
        display: flex;
        column-gap: 10px;
        align-items: center;

        > span {
          display: block;
          color: $muted-text;

          &.points {
            background: $green;
            color: #fff;
            font-weight: 600;
            line-height: 22px;
            font-size: 13px;
            padding: 0 6px;
            border-radius: 3px;
          }
        }
      }

      > .status-blurb {
        .alert {
          display: block;

          > .status-header {
            font-family: $font-family-sans-serif;
            font-weight: 600;

            + * {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}
</style>
