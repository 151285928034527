<script setup>
import { messageTemplateToHTML } from '@/utils'
import { onMounted, ref } from 'vue'
import PostMeta from '@/components/PostMeta.vue'

const props = defineProps({
  data: Object,
})

const celebration = ref({ ...props.data })
const rendering = ref(false)
const messageContent = ref(null)

async function renderMessage() {
  const elem = messageContent.value

  const nodes = messageTemplateToHTML({
    post: { message: celebration.value.generated_message },
    relevantUsers: [celebration.value.user_id],
  })

  elem.replaceChildren(...nodes)

  rendering.value = false
}

onMounted(() => {
  renderMessage()
})
</script>

<template>
  <div :class="['celebration-announcement']">
    <div v-if="!isPreview" class="header">
      <div class="users">
        <user-card
          v-if="celebration.user_id !== 'BOT'"
          :user="celebration.user_id"
        ></user-card>
      </div>
      <post-meta :post="celebration" />
    </div>

    <div
      v-if="celebration.generated_card"
      class="post-card"
      :style="{ 'background-image': `url('${celebration.generated_card}')` }"
    ></div>

    <div ref="messageContent" :class="['content', { rendering }]"></div>
  </div>
</template>

<style lang="scss" scoped>
.celebration-announcement {
  display: block;

  > .reaction-bar {
    margin: 15px 0 0;
  }

  > .comment-bar {
    margin: 20px 0 0;
  }

  > .header {
    display: grid;
    grid-template-columns: 1fr min-content;

    > .users {
      display: flex;
      align-items: center;

      .user--card {
        font-size: 14px;

        .timestamp {
          font-size: 12px;
          color: $muted-text;
          line-height: 12px;
          margin: 3px 0 0;
          display: block;
        }
      }

      > i {
        display: block;
        margin: 0 15px;
        color: $gray-300;
      }

      > ul {
        display: flex;
        padding: 0;
        margin: 0;

        > li {
          display: block;
        }
      }
    }
  }

  .post-card {
    overflow: hidden;
    border-radius: $border-radius;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 15px 0;
    position: relative;

    &::before {
      content: '';
      padding: 0 0 56.25%;
      display: block;
    }

    &.preview {
      &::after {
        content: 'PREVIEW';
        background: rgba($light, 0.6);
        padding: 0 5px;
        line-height: 14px;
        font-size: 12px;
        text-transform: uppercase;
        position: absolute;
        right: 15px;
        top: 10px;
        border-radius: 4px;
      }
    }

    &.rendering {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .content {
    &.rendering {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &.preview {
    > .header {
      display: block;
    }
  }
}
</style>
