<!-- eslint-disable no-unreachable -->
<script setup>
import { useTypesense } from '@/typesenseClient'
import { UserSegments } from '@/api'
import Fuzzy from '@leeoniya/ufuzzy'
import { chunk, debounce } from 'lodash-es'
import { computed, nextTick, reactive, ref, watch } from 'vue'
import ADLER32 from 'adler-32'
import ProfilePicture from '@/components/userCard/ProfilePicture.vue'
import { useToast } from 'vue-toastification'

const props = defineProps({
  modelValue: Array,
  allowSegments: Boolean,
  activeOnly: Boolean,
})
const emit = defineEmits(['update:modelValue'])

const toast = useToast()

const query = ref('')
const searchInput = ref(null)

const userSegments = ref(null)
const segmentSearch = new Fuzzy()

async function populateSegments() {
  const [err, resp] = await UserSegments.listSegments()

  if (err) {
    // HAndle error
    return
  }

  userSegments.value = resp
}

if (props.allowSegments === true) populateSegments()

const selectedUsers = ref(
  // eslint-disable-next-line no-nested-ternary
  props.modelValue
    ? Array.isArray(props.modelValue)
      ? props.modelValue.map((u) => ({
          type: typeof u === 'string' ? 'UserID' : 'User',
          user: u,
          status: typeof u === 'string' ? 'PENDING' : undefined,
        }))
      : [{ type: 'UserSegment', segment: props.modelValue }]
    : []
)

const hasSegmentSelected = computed(
  () =>
    selectedUsers.value.length === 1 &&
    selectedUsers.value[0].type === 'UserSegment'
)

const allowSegmentSelection = computed(
  () => selectedUsers.value.findIndex((sU) => sU.type === 'User') === -1
)

const sortedSelectedUsers = computed(() =>
  [...selectedUsers.value].sort((a) => {
    if (a.status === 'NOT_FOUND') return -1
    // if (a.type === 'User' && b.type === 'User')
    //   return a.user.full_name.localeCompare(b.user.full_name)

    return 0
  })
)

const typesenseClient = useTypesense()

function handlePaste(e) {
  const pasted = e.clipboardData.getData('text')
  const parsedPasted = pasted
    .split(/(\r\n|\r|\n)|,|\s/gm)
    .filter((p) => !!p && p.toString().trim().length > 0)
  const hasEmail = parsedPasted.some((p) =>
    /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/.test(p)
  )

  if (!hasEmail) return

  e.preventDefault()

  selectedUsers.value.unshift(
    ...parsedPasted
      .filter((v) => /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/.test(v))
      .filter((v, i, a) => a.indexOf(v) === i)
      .map((p) =>
        reactive({
          type: 'PastedEmail',
          status: 'PENDING',
          value: p,
        })
      )
  )
}

function checkForDuplicates() {
  let dupeIndex = 0
  while (dupeIndex > -1) {
    dupeIndex = selectedUsers.value.findIndex(
      (r, oI) =>
        r.type === 'User' &&
        selectedUsers.value.findIndex(
          (d, i) => d.type === 'User' && i !== oI && d.user.id === r.user.id
        ) > -1
    )

    if (dupeIndex === -1) break

    selectedUsers.value.splice(dupeIndex, 1)
  }
}

async function resolveUserIDs() {
  console.log('resolve ids')
  const toResolve = selectedUsers.value.filter(
    (u) => u.type === 'UserID' && u.status === 'PENDING'
  )

  if (toResolve.length === 0) return

  const userQueries = toResolve.map((v) => ({
    collection: 'users',
    filter_by: `id:=${v.user}`,
    q: '*',
  }))

  const chunks = chunk(userQueries, 50)
  // console.log(chunks)

  const results = await Promise.all(
    chunks.map(async (queryChunk) => {
      let resp
      let err
      try {
        resp = await typesenseClient.multiSearch
          .perform({ searches: queryChunk })
          .then(({ results: tsResults }) =>
            tsResults.map((r, i) =>
              r.found > 0
                ? {
                    index: i,
                    user: r.hits[0].document,
                    status: 'RESOLVED',
                  }
                : {
                    index: i,
                    status: 'NOT_FOUND',
                  }
            )
          )
      } catch (e) {
        err = e
        console.log(e)
      }

      return resp || err
    })
  ).then((r) => r.flat())
  // ).then((r) => Array.prototype.concat.apply([], r))

  // if (err) {
  //   toResolve.forEach((v) => {
  //     v.status = 'ERROR'
  //   })

  //   return
  // }

  results.forEach((r, i) => {
    toResolve[i].status = r.status

    if (r.status === 'RESOLVED') {
      toResolve[i].type = 'User'
      toResolve[i].user = r.user
    }
  })
}

const resolvePastedEmails = debounce(async () => {
  const toResolve = selectedUsers.value.filter(
    (u) => u.type === 'PastedEmail' && u.status === 'PENDING'
  )

  if (toResolve.length === 0) return

  toResolve.forEach((v) => {
    v.status = 'PROCESSING'
  })

  const userQueries = {
    searches: toResolve.map((v) => ({
      collection: 'users',
      filter_by: `email:=${v.value.toLowerCase()}`,
      q: v.value.toLowerCase(),
      query_by: 'email',
    })),
  }

  let resp
  let err
  try {
    resp = await typesenseClient.multiSearch
      .perform(userQueries)
      .then(({ results }) =>
        results.map((r) =>
          r.found > 0
            ? {
                query: r.request_params.q,
                user: r.hits[0].document,
                status: 'RESOLVED',
              }
            : {
                query: r.request_params.q,
                status: 'NOT_FOUND',
              }
        )
      )
  } catch (e) {
    err = e
  }

  if (err) {
    toResolve.forEach((v) => {
      v.status = 'ERROR'
    })

    toast.error(`Something went wrong, please try pasting emails again.`)

    // Clear out error emails
    // connor@hithrive.com, joshua@hithrive.com
    let errorIndex = 0
    while (errorIndex > -1) {
      errorIndex = selectedUsers.value.findIndex(
        ({ type, status }) => type === 'PastedEmail' && status === 'ERROR'
      )

      if (errorIndex === -1) break

      selectedUsers.value.splice(errorIndex, 1)
    }

    return
  }

  resp.forEach((r) => {
    const fromToResolve = toResolve.find(
      (v) => v.value.toLowerCase() === r.query
    )

    fromToResolve.status = r.status

    if (r.status === 'RESOLVED') {
      fromToResolve.type = 'User'
      fromToResolve.user = r.user
    }
  })

  checkForDuplicates()
}, 250)

watch(
  selectedUsers,
  (v) => {
    if (v.length === 1 && v[0].type === 'UserSegment') {
      emit('update:modelValue', v[0].segment)
      return
    }

    resolvePastedEmails()
    resolveUserIDs()

    nextTick(() => {
      console.log('emit')
      emit(
        'update:modelValue',
        selectedUsers.value
          .filter(({ type }) => type === 'User')
          .map(({ user }) => user)
      )
    })
  },
  {
    immediate: true,
    deep: true,
  }
)

function removeRecipient(recipient) {
  const index = selectedUsers.value.findIndex((s) => s === recipient)

  if (index === -1) return

  selectedUsers.value.splice(index, 1)
}

const searchSuggestions = ref(null)
const populateSearchSuggestions = debounce(async () => {
  let segmentResults
  if (props.allowSegments) {
    const [segmentIxs] = segmentSearch.search(
      userSegments.value.map((s) => s.name),
      query.value
    )

    segmentResults = userSegments.value.filter((s, i) => segmentIxs.includes(i))
  }

  const { results } = await typesenseClient.multiSearch.perform({
    searches: [
      {
        collection: 'users',
        q: query.value,
        query_by: 'full_name',
      },
    ],
  })

  const [usersResults] = results

  searchSuggestions.value = {
    users: usersResults.hits,
    segments: segmentResults,
  }
}, 250)

const filteredUserSuggestions = computed(() => {
  const selectedIds = selectedUsers.value
    .filter(({ type }) => type === 'User')
    .map(({ user }) => user.id)

  return searchSuggestions.value === null
    ? null
    : searchSuggestions.value.users.filter(
        ({ document }) => !selectedIds.includes(document.id)
      )
})

const filteredSegmentSuggestions = computed(() => {
  const selectedIds = selectedUsers.value
    .filter(({ type }) => type === 'UserSegment')
    .map(({ segment }) => segment.id)

  return searchSuggestions.value === null
    ? null
    : searchSuggestions.value.segments.filter(
        (segment) => !selectedIds.includes(segment.id)
      )
})

function handleInput() {
  if (query.value.trim().length === 0) {
    searchSuggestions.value = null
    return
  }

  populateSearchSuggestions()
}
const filterFieldFocus = ref(false)
const setFilterFieldFocus = debounce((action) => {
  filterFieldFocus.value = action === 'focus'
}, 250)

const showSuggestionsDropdown = computed(
  () => filterFieldFocus.value === true && searchSuggestions.value !== null
)

const hoveredSuggestion = ref(-1)
const suggestionsElem = ref(null)

function selectSuggested(opt) {
  const [type] = opt.id.split('_')

  filterFieldFocus.value = false
  searchInput.value.blur()
  hoveredSuggestion.value = -1

  query.value = ''

  if (type === 'user')
    selectedUsers.value.unshift({
      user: { ...opt },
      type: 'User',
    })
  else
    selectedUsers.value.unshift({
      segment: { ...opt },
      type: 'UserSegment',
    })

  handleInput()

  checkForDuplicates()
}

function handleKeyDown(e) {
  if (e.keyCode === 40) {
    e.preventDefault()
    const listElems = suggestionsElem.value.querySelectorAll('li')
    // Arrow down
    if (hoveredSuggestion.value === listElems.length - 1)
      hoveredSuggestion.value = 0
    else hoveredSuggestion.value += 1
  } else if (e.keyCode === 38) {
    e.preventDefault()
    const listElems = suggestionsElem.value.querySelectorAll('li')
    // Arrow up
    if (hoveredSuggestion.value === 0)
      hoveredSuggestion.value = listElems.length - 1
    else hoveredSuggestion.value -= 1
  } else if (e.keyCode === 13) {
    const listElems = suggestionsElem.value.querySelectorAll('li')
    const selectedElem = listElems[hoveredSuggestion.value]
    const resultId = selectedElem.getAttribute('data-resultId')
    const [type] = resultId.split('_')

    let selected
    if (type === 'user')
      selected = searchSuggestions.value.users.find(
        ({ document }) => document.id === resultId
      ).document
    else if (type === 'userSegment')
      selected = searchSuggestions.value.segments.find(
        (segment) => segment.id === resultId
      )

    selectSuggested(selected)
  }
}

watch(hoveredSuggestion, (hoveredIndex) => {
  const listElems = suggestionsElem.value.querySelectorAll('li')

  listElems.forEach((elem) => elem.classList.remove('hover'))

  if (hoveredIndex === -1) return

  listElems[hoveredIndex].classList.add('hover')
})

function handleSuggestionHover(e) {
  const listElems = suggestionsElem.value.querySelectorAll('li')
  const hoveredElem = e.target

  hoveredSuggestion.value = [...listElems].findIndex(
    (elem) => elem === hoveredElem
  )
}
</script>

<template>
  <div class="bulk-user-selector">
    <div v-if="!hasSegmentSelected" class="filter-wrap">
      <input
        ref="searchInput"
        v-model="query"
        type="text"
        :placeholder="
          allowSegments
            ? 'Search for users, segments, or paste email addresses...'
            : 'Search for users or paste email addresses...'
        "
        class="form-control"
        @paste="handlePaste"
        @input="handleInput"
        @keydown="handleKeyDown"
        @focus="setFilterFieldFocus($event.type)"
        @blur="setFilterFieldFocus($event.type)"
      />

      <div
        v-if="showSuggestionsDropdown"
        ref="suggestionsElem"
        class="suggestions dropdown-menu"
      >
        <div
          v-if="allowSegments && allowSegmentSelection"
          class="segment-suggestions"
        >
          <h4>User segments</h4>
          <ol v-if="filteredSegmentSuggestions">
            <li
              v-for="result in filteredSegmentSuggestions"
              :key="`searchSuggestion_${result.id}`"
              :data-resultId="result.id"
              @mouseenter="handleSuggestionHover"
              @mouseleave="hoveredSuggestion = -1"
            >
              <a href="#" @click.prevent="selectSuggested(result)">
                <strong>{{ result.name }}</strong
                ><small v-if="result.recipient_alias">{{
                  result.recipient_alias
                }}</small>
              </a>
            </li>
          </ol>
        </div>
        <div class="user-suggestions">
          <h4>Team members</h4>
          <ol v-if="filteredUserSuggestions">
            <li
              v-for="result in filteredUserSuggestions"
              :key="`searchSuggestion_${result.id}`"
              :data-resultId="result.document.id"
              @mouseenter="handleSuggestionHover"
              @mouseleave="hoveredSuggestion = -1"
            >
              <a href="#" @click.prevent="selectSuggested(result.document)">
                <profile-picture :user="result.document" />
                <div v-html="result.highlights[0].snippet"></div>
              </a>
            </li>
          </ol>
        </div>
      </div>
    </div>

    <ol v-if="selectedUsers.length > 0">
      <li
        v-for="selected in sortedSelectedUsers"
        :key="`selectedUser_${ADLER32.str(JSON.stringify(selected))}`"
        :class="[
          {
            error:
              selected.status === 'NOT_FOUND' || selected.status === 'ERROR',
          },
        ]"
      >
        <template v-if="selected.type === 'User'">
          <user-card :user="selected.user" />

          <div class="actions">
            <button @click.prevent="removeRecipient(selected)">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </template>

        <template v-if="selected.type === 'UserSegment'">
          <strong>{{ selected.segment.name }}</strong
          ><small>User Segment</small>

          <div class="actions">
            <button @click.prevent="removeRecipient(selected)">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </template>

        <template v-else>
          <div
            v-if="
              selected.status === 'PENDING' || selected.status === 'PROCESSING'
            "
            class="resolving-email"
          >
            <loading-spinner color="dark" />
            <strong>{{ selected.value }}</strong>
          </div>

          <div
            v-else-if="selected.status === 'NOT_FOUND'"
            class="email-not-found"
          >
            <i class="fas fa-circle-exclamation"></i>
            <strong>{{ selected.value }}</strong>
            <button
              class="btn btn-link btn-sm"
              @click.prevent="removeRecipient(selected)"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </template>
      </li>
    </ol>
  </div>
</template>

<style lang="scss" scoped>
.bulk-user-selector {
  max-width: 512px;

  > .filter-wrap {
    margin: 0 0 15px;
    position: relative;

    > input {
      width: 100%;
    }

    > .suggestions {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      margin-top: 10px;
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 10px;

      > .user-suggestions,
      > .segment-suggestions {
        > h4 {
          font-weight: 600;
          font-family: $font-family-sans-serif;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          color: $muted-text;
          font-size: 12px;
          margin: 0 0 4px;
        }

        > ol {
          padding: 0;
          margin: 0;
          display: grid;
          grid-template-columns: 100%;

          > li {
            display: block;

            > a {
              display: flex;
              align-items: center;
              column-gap: 7px;
              padding: 5px;
              border-radius: $border-radius - 4px;
              color: $black;
              text-decoration: none;

              > .user--profile-picture {
                width: 28px;
              }

              :deep(mark) {
                background: none;
                padding: 0;
                font-weight: 600;
              }
            }

            &.hover {
              > a {
                background: $highlight-gray;
              }
            }
          }
        }
      }
    }
  }

  > ol {
    padding: 0;
    margin: 0;
    display: block;
    overflow: hidden;
    overflow-y: auto;
    border-radius: $border-radius;
    border: 1px solid $border-color;
    background: #fff;
    max-height: 600px;

    > li {
      display: block;
      padding: 0.75rem 1rem;
      border-bottom: 1px solid $border-color;
      position: relative;

      :deep(> .user--card) {
        > .user--profile-picture {
          width: 28px;
        }
      }

      &.error {
        background: $danger-bg-subtle;
      }

      > .resolving-email {
        display: flex;
        align-items: center;
        column-gap: 15px;
      }

      > .email-not-found {
        display: flex;
        align-items: center;
        column-gap: 10px;
        color: $danger-text-emphasis;

        > i {
          font-size: 20px;
        }

        > button {
          margin: 0 -7px 0 auto;
          color: $danger-text-emphasis;
        }
      }

      &:last-child {
        border: 0;
      }

      &:hover {
        > .actions {
          transform: translateX(0);
        }
      }

      > strong {
        + small {
          background: $light;
          border-radius: 100em;
          line-height: 16px;
          vertical-align: middle;
          margin: 0 0 0 5px;
          padding: 0 8px;
          font-size: 10px;
          color: $muted-text;
          display: inline-block;
          font-weight: 600;
        }
      }

      .actions {
        margin: 0 0 0 auto;
        padding: 0 0 0 10px;
        font-size: 10px;
        font-weight: 600;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        transform: translateX(100%);
        transition: transform 0.25s $curve;

        > button {
          background: $gray-200;
          color: $muted-text;
          border-radius: 6px;
          width: 40px;
          height: 40px;
          margin: 0 5px;
          line-height: 10px;
          border: 0;
          outline: 0;
          text-align: center;
          padding: 0;
          display: block;

          &.edit {
            background: $primary;
            color: #fff;
          }

          > i {
            font-size: 14px;
          }

          > span {
            display: block;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 9px;
            letter-spacing: 0.5px;
            margin: 5px 0 0;
          }
        }
      }
    }
  }
}
</style>
