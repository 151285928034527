<script setup>
import { computed, onBeforeUnmount, onMounted, readonly, ref } from 'vue'
import { useLocaleFormatter, shoutoutToNodes } from '@/utils'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store'
import ProfilePicture from '@/components/userCard/ProfilePicture.vue'
import userListModal from '@/components/client/modals/UserList.vue'
import PostMeta from '@/components/PostMeta.vue'
import { useModal } from '@/modal'

const userStore = useUserStore()

const { formatList, formatPoints } = useLocaleFormatter()
const { user: currentUser } = storeToRefs(userStore)

const props = defineProps({
  data: Object,
  timelineMode: { type: Boolean, default: false },
})

const shoutout = readonly(props.data)
const shoutoutCard = ref(null)

const { openModal } = useModal()

function renderMessage(elem) {
  const nodes = shoutoutToNodes(shoutout)
  elem.replaceChildren(...nodes)
}

const forUsers = computed(() =>
  [...shoutout.for_users].sort((a, b) =>
    a.id === currentUser.value.id || b.id === currentUser.value.id ? -1 : 1
  )
)

const recipientPreview = computed(() =>
  forUsers.value.length > 4 ? forUsers.value.slice(0, 4) : forUsers.value
)

const postTitle = computed(() => {
  const recipientsForText =
    forUsers.value.length > recipientPreview.value.length
      ? [
          ...recipientPreview.value,
          `${forUsers.value.length - recipientPreview.value.length} more`,
        ]
      : recipientPreview.value

  return `<strong>${
    shoutout.created_by.id === currentUser.value.id
      ? 'You'
      : shoutout.created_by.full_name
  }</strong> recognized
        ${formatList(
          recipientsForText.map((u) => {
            if (typeof u === 'string')
              return `<a href="#" class="addl-recipients-text">${u}</a>`

            return u.id === currentUser.value.id
              ? '<strong>you</strong>'
              : `<strong>${u.full_name}</strong>`
          })
        )}${
          shoutout.tagged_value
            ? ` for <strong>${shoutout.tagged_value.text}</strong>`
            : ''
        }`
})

const showPoints = computed(
  () =>
    (shoutout.for_users.some((u) => u.id === currentUser.value.id) ||
      shoutout.created_by.id === currentUser.value.id) &&
    shoutout.points > 0
)

function handleAddlUserClick(e) {
  e.preventDefault()

  // Open modal showing users
  openModal(userListModal, {
    users: forUsers.value,
    title: 'Shoutout Recipients',
  })
}

onMounted(() => {
  const addlRecipientLink = shoutoutCard.value.querySelector(
    '.addl-recipients-text'
  )
  if (!addlRecipientLink) return

  addlRecipientLink.addEventListener('click', handleAddlUserClick)
})

onBeforeUnmount(() => {
  const addlRecipientLink = shoutoutCard.value.querySelector(
    '.addl-recipients-text'
  )
  if (!addlRecipientLink) return

  addlRecipientLink.removeEventListener('click', handleAddlUserClick)
})
</script>

<template>
  <div ref="shoutoutCard" class="shoutout-card">
    <div class="header">
      <div class="sender-title-wrap">
        <profile-picture :user="shoutout.created_by" />
        <span class="title" v-html="postTitle"></span>
      </div>

      <post-meta :post="shoutout" :hide-dropdown="timelineMode" />
    </div>

    <div class="users">
      <ul>
        <li
          v-for="recipient in recipientPreview"
          :key="`shoutout_${shoutout.id}_recipient_${recipient.id}`"
        >
          <profile-picture :user="recipient" />
        </li>

        <li
          v-if="recipientPreview.length < shoutout.for_users.length"
          class="recipientPreview-more"
        >
          <span>+{{ shoutout.for_users.length - recipientPreview.length }}</span
          >more
        </li>
      </ul>
    </div>

    <div :ref="renderMessage" class="content"></div>

    <div class="attachments">
      <div
        v-for="(attachment, attachmentIndex) in shoutout.attachments"
        :key="`shoutout_${shoutout.id}_attachment_${attachmentIndex}`"
        :class="[`${attachment.type}-attachment`]"
      >
        <div v-if="attachment.type === 'gif'" class="object-wrap">
          <video :src="attachment.mp4" muted autoplay loop></video>
        </div>
      </div>
    </div>

    <div v-if="showPoints" class="tags">
      <div v-if="showPoints" class="points">
        +{{ formatPoints(shoutout.points) }}
      </div>
    </div>

    <ReactionBar :read-only="timelineMode" :entity-id="shoutout.id" />
    <CommentBar :read-only="timelineMode" :entity-id="shoutout.id" />
  </div>
</template>
