import { SearchClient } from 'typesense'
import { useUserStore } from './store'

export function useTypesense() {
  const userStore = useUserStore()

  const typesense = new SearchClient({
    nodes: [
      {
        host: 'yt7fovaj6x3lb5z9p-1.a1.typesense.net',
        protocol: 'https',
        port: '443',
      },
    ],
    apiKey: userStore.user.typesense_token,
  })

  return typesense
}
